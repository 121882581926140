<template>
  <div class="genic-teams" :class="{ 'genic-team-reload-now': reloadNow }">
    <div class="genic-teams-reload" v-if="reloadNow">
      <p class="m-0 font-size-12">
        We’ve fine-tuned Genic Projects under the hood. When you find time,
        refresh this tab to experience the latest Genic Projects.
        <a
          class="genic-teams-reload-btn"
          href="javascript:void(0)"
          v-on:click="reloadPage"
          >Refresh</a
        >
      </p>
    </div>
    <div class="top-bar-loader">
      <vue-topprogress
        ref="topProgress"
        color="#F7941E"
        colorShadow
        :height="10"
      ></vue-topprogress>
    </div>
    <router-view></router-view>
  </div>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/inter/inter.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

// Main demo style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { vueTopprogress } from "vue-top-progress";
import { TopBarLoaderEventBus } from "@/core/lib/topbar.loader.lib";
import { setTempCookie, getCookie } from "@/core/plugins/bt-cookie.js";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "BthrustGenicTeams",
  data: () => {
    return {
      updateCount: 0,
      reloadNow: false,
      timeInterval: null,
      timeIntervalLimit: 5000,
    };
  },
  components: {
    vueTopprogress,
  },
  /*updated() {
    this.updateCount = this.updateCount + 1;
    if (this.updateCount <= 10) {
      clearInterval(this.timeInterval);
    }
  },*/
  methods: {
    reloadPage() {
      window.location.reload();
    },
    monitorReload() {
      const _this = this;
      clearInterval(_this.timeInterval);
      _this.timeInterval = setInterval(function () {
        let lastLoadTime = getCookie("_btlrld_tmp");
        if (typeof lastLoadTime != "string") {
          _this.reloadNow = true;
        }
      }, _this.timeIntervalLimit);
    },
  },
  mounted() {
    this.monitorReload();
    setTempCookie({
      key: "_btlrld_tmp",
      value: moment().format("YYYY-MM-DD HH:mm"),
      time: 60 * 10,
    });
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);

    const _this = this;

    _this.$refs.topProgress.start();

    TopBarLoaderEventBus.$on("start:loader", function () {
      _this.$refs.topProgress.decrease(50);
      _this.$refs.topProgress.start();
    });

    TopBarLoaderEventBus.$on("done:loader", function () {
      _this.$refs.topProgress.done();
    });

    TopBarLoaderEventBus.$on("fail:loader", function () {
      _this.$refs.topProgress.fail();
    });
  },
  beforeDestroy() {
    TopBarLoaderEventBus.$off("start:loader");
    TopBarLoaderEventBus.$off("done:loader");
    TopBarLoaderEventBus.$off("fail:loader");
  },
};
</script>
