import { GET, POST } from "@/core/services/store/request.module";
import { map, cloneDeep, find } from "lodash";
import ObjectPath from "object-path";
import { v4 as uuidv4 } from "uuid";

// action types
export const PERFORM_CALCULATION = "LtxPerformCalculation";
export const SET_LINE_ITEM = "LtxSetLineItem";
export const SET_PACKAGE = "LtxSetPackage";
export const SET_ADTITLE = "LtxSetAdTitle";
export const SET_ADSELECT = "LtxSetAdSelect";
export const SET_ADLEVEL2 = "LtxSetAdLevel2";
export const SET_ADWORK = "LtxSetAdWork";
export const SET_UOM_LIST = "LtxSetUomList";
export const SET_CALCULATION = "LtxSetCalculation";
export const UPDATE_ADTITLE = "LtxUpdateAdTitle";
export const UPDATE_IMAGE = "LtxUpdateAdImage";
export const UPDATE_ADWORK = "LtxUpdateAdWork";
export const UPDATE_ADLEVEL2 = "LtxUpdateLevel2";
export const LEVEL_FILTER = "LtxLevel2Filter";
export const ADSELECT_FILTER = "LtxAdSelectFilter";
export const EMIT_VALUE = "LtxEmitValue";
export const SET_CHILD_ITEM = "LtxSetChildItem";
export const SET_LEVEL2_CHILD_ITEM = "LtxSetLevel2ChildItem";
export const ADD_ITEM = "LtxAddItem";
export const ADD_CHILD_ITEM = "LtxAddChildItem";
export const ADD_LEVEL2_CHILD_ITEM = "LtxAddLevel2ChildItem";
export const DELETE_PACKAGE = "LtxDeletePackage";
export const DELETE_ADTITLE = "LtxDeleteAdTitle";
export const DELETE_ADWORK = "LtxDeleteAdWork";
export const DELETE_LEVEL2 = "LtxDeleteLevel2";
export const CLEAR_LINE_ITEM = "LtxClearLineItem";
export const GET_CHILD_LIST = "LtxGetChildList";
export const SET_CHILD_OPTIONAL = "LtxSetChildOptional";
export const SET_CHILD_REMOVED = "LtxSetChildRemoved";
export const SET_DISCOUNT = "LtxSetDiscount";
export const SET_SUMMARY_REPORT = "LtxSetSummaryReport";
export const SET_GST = "LtxSetGst";

const defaultState = {
  is_summary_report: false,
  packages: [],
  line_items: [],
  uom_list: [],
  adtitle_list: [],
  adwork_list: [],
  ad_select: {},
  ad_level2: {},
  child_items: {},
  level2_child_items: {},
  calculation: {
    sub_total: 0,
    optional_total: 0,
    discount_value: 0,
    discount_type: 1 /* 1 => Amount, 2 => Percentage */,
    discount_amount: 0,
    discount_two_value: 0,
    discount_two_type: 1 /* 1 => Amount, 2 => Percentage */,
    discount_two_amount: 0,
    acc_disc_amnt: 0,
    tax_applied: 1,
    tax_value: 9,
    taxable_amount: 0,
    tax_amount: 0,
    adjustment: 0,
    total: 0,
    acc_supplier: 0,
    acc_profit: 0,
    acc_markup_percent: 0,
    acc_profit_percent: 0,
  },
  default_item: {
    barcode: null,
    charges: 0,
    company_cost: 0,
    uom: "",
    pagebreak: false,
    description: null,
    internal_note: null,
    id: 0,
    is_optional: 0,
    package_id: 0,
    product: null,
    product_id: 0,
    product_image: null,
    product_type: "adtitle",
    net_price: 0,
    profit: 0,
    quantity: 0,
    rate: 0,
    strike: 0,
    status: true,
    new_item: false,
    edit_supplier_cost: false,
    total: 0,
    child_sum: null,
    optional_child_sum: 0,
    label: null,
    show_label: false,
    uuid: null,
  },
  default_child_item: {
    product_id: 0,
    description: null,
    internal_note: null,
    quantity: 1,
    uom: null,
    show_label: false,
    edit_supplier_cost: false,
    company_cost: null,
    total_company_cost: null,
    profit: null,
    profit_percent: null,
    markup_percent: null,
    label: null,
    is_optional: 0,
    strike: 0,
    optional_child_sum: 0,
    new_item: true,
    child_sum: 0,
    rate: 0,
    total: 0,
    uuid: null,
  },
  default_level2_child_item: {
    product_id: 0,
    description: null,
    internal_note: null,
    quantity: 1,
    uom: null,
    is_optional: 0,
    new_item: true,
    edit_supplier_cost: false,
    company_cost: null,
    total_company_cost: null,
    profit: null,
    profit_percent: null,
    markup_percent: null,
    strike: 0,
    rate: 0,
    total: 0,
    uuid: null,
  },
};

const alphabets = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

// mutation types
export default {
  state: {
    is_summary_report: defaultState.is_summary_report,
    packages: cloneDeep(defaultState.packages),
    line_items: cloneDeep(defaultState.line_items),
    uom_list: cloneDeep(defaultState.uom_list),
    adtitle_list: cloneDeep(defaultState.adtitle_list),
    adwork_list: cloneDeep(defaultState.adwork_list),
    ad_select: cloneDeep(defaultState.ad_select),
    ad_level2: cloneDeep(defaultState.ad_level2),
    child_items: cloneDeep(defaultState.child_items),
    level2_child_items: cloneDeep(defaultState.level2_child_items),
    calculation: cloneDeep(defaultState.calculation),
    default_item: cloneDeep(defaultState.default_item),
    default_child_item: cloneDeep(defaultState.default_child_item),
    default_level2_child_item: cloneDeep(defaultState.default_level2_child_item)
  },
  getters: {
    ltxAlphabets() {
      return alphabets;
    },
    ltxPackage(state) {
      return state.packages;
    },
    ltxSummaryReport(state) {
      return state.is_summary_report;
    },
    ltxCalculation(state) {
      return state.calculation;
    },
    ltxUomList(state) {
      return state.uom_list;
    },
    ltxLineItem(state) {
      return state.line_items;
    },
    ltxAdTitleList(state) {
      return state.adtitle_list;
    },
    ltxAdLevel2List(state) {
      return state.ad_level2;
    },
    ltxAdSelectList(state) {
      return state.ad_select;
    },
    ltxChildLineItem(state) {
      return state.child_items;
    },
    ltxLevel2ChildLineItem(state) {
      return state.level2_child_items;
    },
  },
  actions: {
    [UPDATE_IMAGE](context, { index, images }) {
      if (images.length) {        
        const line_items = cloneDeep(context.state.line_items);

        line_items[index].new_images = images;

        context.commit(SET_LINE_ITEM, line_items);
      }
    },
    [UPDATE_ADTITLE](context, { productId, index }) {
      const row = find(context.state.adtitle_list, { id: productId });
      if (row?.id) {
        context.state.line_items[index].barcode = null;
        context.state.line_items[index].charges = 0;
        context.state.line_items[index].company_cost = 0;
        context.state.line_items[index].uom = null;
        context.state.line_items[index].images = row.images;
        context.state.line_items[index].new_images = [];
        context.state.line_items[index].id = null;
        context.state.line_items[index].is_optional = 0;
        context.state.line_items[index].package_id = 0;
        context.state.line_items[index].product = row.name;
        context.state.line_items[index].main_category = row.main_category;
        context.state.line_items[index].description = row.description;
        context.state.line_items[index].product_id = row.id;
        context.state.line_items[index].product_image = null;
        context.state.line_items[index].product_type = "adtitle";
        context.state.line_items[index].net_price = 0;
        context.state.line_items[index].profit = 0;
        context.state.line_items[index].quantity = 0;
        context.state.line_items[index].rate = 0;
        context.state.line_items[index].strike = 0;
        context.state.line_items[index].status = true;
        context.state.line_items[index].edit_supplier_cost = false;
        context.state.line_items[index].total = 0;
        context.state.line_items[index].child_sum = 0;
        context.state.line_items[index].optional_child_sum = 0;
        context.state.line_items[index].label = null;
        context.state.line_items[index].show_label = false;

        context.dispatch(ADSELECT_FILTER, { productId });
      }
    },
    [UPDATE_ADWORK](context, { productId, rowUuid, index }) {
      let row = find(context.state.adwork_list, { product_id: productId });

      if (row?.product_id) {
        const child_items = cloneDeep(context.state.child_items);
        
        if (child_items[rowUuid][index]) {
          child_items[rowUuid][index].product_id = row.product_id;
          child_items[rowUuid][index].description = row.description;
          child_items[rowUuid][index].internal_note = row.internal_note;
          child_items[rowUuid][index].quantity = 1;
          child_items[rowUuid][index].uom = row.uom;
          child_items[rowUuid][index].rate = row.rate;
          child_items[rowUuid][index].total = row.total;
          child_items[rowUuid][index].company_cost = row.company_cost;
        }

        context.commit(SET_CHILD_ITEM, child_items);
      }
    },
    [UPDATE_ADLEVEL2](context, { level2Id, rowUuid, crowUuid, clindex, productId }) {
      if (context.state.ad_level2[productId]?.length) {
        let row = find(context.state.ad_level2[productId], { id: level2Id }, { divider: null });
        if (row) {
          const level2_index = `${rowUuid}.${crowUuid}.${clindex}`;

          const level2_child_items = cloneDeep(context.state.level2_child_items);

          if (ObjectPath.has(level2_child_items, level2_index)) {

            level2_child_items[rowUuid][crowUuid][clindex].product_id = row.product_id;
            level2_child_items[rowUuid][crowUuid][clindex].title = row.text;
            level2_child_items[rowUuid][crowUuid][clindex].description = row.description;
            level2_child_items[rowUuid][crowUuid][clindex].internal_note = row.internal_note;
            level2_child_items[rowUuid][crowUuid][clindex].quantity = 1;
            level2_child_items[rowUuid][crowUuid][clindex].uom = row.uom;
            level2_child_items[rowUuid][crowUuid][clindex].rate = row.rate;
            level2_child_items[rowUuid][crowUuid][clindex].total = row.total;
            level2_child_items[rowUuid][crowUuid][clindex].company_cost = row.company_cost;

            context.commit(SET_LEVEL2_CHILD_ITEM, level2_child_items);

          }
        }
      }
    },
    [ADSELECT_FILTER](context, { productId }) {
      if (productId && !context.state.ad_select[productId]?.length) {
        context
          .dispatch(GET, { url: `adtitle/${productId}` })
          .then(({ data }) => {
            const adw_db_relation = data?.adw_db_relation ?? [];
            if (adw_db_relation?.length) {
              const ad_select = [];
              for (let i = 0; i < adw_db_relation.length; i++) {
                if (adw_db_relation[i].activated) {
                  ad_select.push({
                    id: adw_db_relation[i].id,
                    text: adw_db_relation[i].name,
                    value: adw_db_relation[i].id,
                    main_category: adw_db_relation[i].main_category,
                  });
                }
              }

              const ad_select_list = cloneDeep(context.state.ad_select);
              ObjectPath.set(ad_select_list, productId, []);
              ad_select_list[productId] = ad_select;
              context.commit(SET_ADSELECT, ad_select_list);
            }
          })
          .catch((error) => {
            console.log({ error });
          });
      }
    },
    [LEVEL_FILTER](context, { productId }) {
      if (productId && !context.state.ad_level2[productId]?.length) {
        context
          .dispatch(GET, { url: `adlevel2/filter?add_work_id=${productId}` })
          .then(({ data }) => {
            const ad_level2_list = cloneDeep(context.state.ad_level2);
            ObjectPath.set(ad_level2_list, productId, []);
            ad_level2_list[productId] = data;
            context.commit(SET_ADLEVEL2, ad_level2_list);
          })
          .catch((error) => {
            console.log({ error });
          });
      }
    },
    [EMIT_VALUE](context) {
      console.log(context);
    },
    [ADD_ITEM](context) {
      const line_items = cloneDeep(context.state.line_items);
      const default_item = cloneDeep(defaultState.default_item);

      default_item.uuid = uuidv4();

      line_items.push(default_item);

      context.commit(SET_LINE_ITEM, line_items);
    },
    [SET_CHILD_OPTIONAL](context, { val, rowUuid, uuid }) {
      const level2_child_items = cloneDeep(context.state.level2_child_items);

      const level2_child = ObjectPath.get(level2_child_items, [rowUuid, uuid], []);

      if (level2_child.length) {

        level2_child.forEach(obj => {
          if (obj.hasOwnProperty('is_optional')) {
            obj.is_optional = val;
            if (obj.is_optional) {
              obj.strike = 0
            }
          }
        });

        ObjectPath.set(level2_child_items, [rowUuid, uuid], level2_child);

        context.commit(SET_LEVEL2_CHILD_ITEM, level2_child_items);

      }
    },
    [SET_CHILD_REMOVED](context, { val, rowUuid, uuid }) {
      const level2_child_items = cloneDeep(context.state.level2_child_items);

      const level2_child = ObjectPath.get(level2_child_items, [rowUuid, uuid], []);

      if (level2_child.length) {

        level2_child.forEach(obj => {
          if (obj.hasOwnProperty('strike')) {
            obj.strike = val;
            if (obj.strike) {
              obj.is_optional = 0
            }
          }
        });

        ObjectPath.set(level2_child_items, [rowUuid, uuid], level2_child);

        context.commit(SET_LEVEL2_CHILD_ITEM, level2_child_items);

      }
    },
    [SET_DISCOUNT](context, { discount_type, discount_value, discount_amount, discount_two_value, discount_two_type, discount_two_amount, adjustment, tax_applied, tax_value }) {
      const calculation = cloneDeep(context.state.calculation);
      calculation.discount_type = Number(discount_type) == 0 ? 1 : Number(discount_type);
      calculation.discount_value = Number(discount_value);
      calculation.discount_amount = Number(discount_amount);
      calculation.discount_two_value = Number(discount_two_value);
      calculation.discount_two_type = Number(discount_two_type) == 0 ? 1 : Number(discount_two_type);
      calculation.discount_two_amount = Number(discount_two_amount);
      calculation.adjustment = Number(adjustment);
      calculation.tax_applied = Number(tax_applied);
      calculation.tax_value = Number(tax_value);
      context.commit(SET_CALCULATION, calculation)
    },
    [SET_GST](context, gst) { 
      defaultState.calculation.tax_applied = 0;
      defaultState.calculation.tax_value = 0;
      if (gst?.percentage) {
        defaultState.calculation.tax_applied = 1;
        defaultState.calculation.tax_value = gst?.percentage;
      }

      const calculation = cloneDeep(context.state.calculation);
      calculation.tax_applied = Number(defaultState.calculation.tax_applied);
      calculation.tax_value = Number(defaultState.calculation.tax_value);
      context.commit(SET_CALCULATION, calculation);
    },
    [ADD_CHILD_ITEM](context, { uuid, productId, indexToInsert }) {
      let child_items = cloneDeep(context.state.child_items);
      const default_child_item = cloneDeep(defaultState.default_child_item);

      if (Array.isArray(child_items) || typeof child_items != 'object') {
        child_items = new Object();
      }

      default_child_item.uuid = uuidv4();

      if (context.state.is_summary_report) {
        default_child_item.new_item = 1;
      }

      context.dispatch(ADSELECT_FILTER, { productId });

      if (!(uuid in child_items)) {
        child_items[uuid] = new Array();
      }

      if (!Array.isArray(child_items[uuid])) {
        child_items[uuid] = new Array();
      }

      if (indexToInsert > -1) {
        const adjustedIndex = indexToInsert + 1;
        child_items[uuid].splice(adjustedIndex, 0, default_child_item);
      } else {
        child_items[uuid].push(default_child_item);
      }

      // ObjectPath.push(child_items, uuid, default_child_item);

      context.commit(SET_CHILD_ITEM, child_items);
    },
    [ADD_LEVEL2_CHILD_ITEM](context, { rowUuid, uuid }) {
      let level2_child_items = cloneDeep(context.state.level2_child_items);
      const default_level2_child_item = cloneDeep(defaultState.default_level2_child_item);

      if (Array.isArray(level2_child_items) || typeof level2_child_items != 'object') {
        level2_child_items = new Object();
      }

      default_level2_child_item.uuid = uuidv4();

      if (context.state.is_summary_report) {
        default_level2_child_item.new_item = 1;
      }

      if (!(rowUuid in level2_child_items)) {
        level2_child_items[rowUuid] = new Object();
      }

      if (!(uuid in level2_child_items[rowUuid])) {
        level2_child_items[rowUuid][uuid] = new Array();
      }

      if (!Array.isArray(level2_child_items[rowUuid][uuid])) {
        level2_child_items[rowUuid][uuid] = new Array();
      }

      level2_child_items[rowUuid][uuid].push(default_level2_child_item);

      // ObjectPath.push(level2_child_items, [rowUuid, uuid], default_level2_child_item);

      context.commit(SET_LEVEL2_CHILD_ITEM, level2_child_items);
    },
    [DELETE_ADTITLE](context, index) {
      const child_items = cloneDeep(context.state.child_items);
      const line_items = cloneDeep(context.state.line_items);

      ObjectPath.del(child_items, index);

      context.commit(SET_CHILD_ITEM, child_items);

      ObjectPath.del(line_items, index);

      context.commit(SET_LINE_ITEM, line_items);
    },
    [DELETE_PACKAGE](context, index) {
      const packages = cloneDeep(context.state.packages);

      ObjectPath.del(packages, index);

      context.commit(SET_PACKAGE, packages);
    },
    [DELETE_ADWORK](context, { rowUuid, index }) {
      const child_items = cloneDeep(context.state.child_items);

      ObjectPath.del(child_items, [rowUuid, index]);

      context.commit(SET_CHILD_ITEM, child_items);
    },
    [DELETE_LEVEL2](context, { rowUuid, uuid, index }) {
      const level2_child_items = cloneDeep(context.state.level2_child_items);
      console.log(JSON.stringify({ rowUuid, uuid, index }));
      console.log(JSON.stringify(level2_child_items));

      ObjectPath.del(level2_child_items, [rowUuid, uuid, index]);
      const test = ObjectPath.get(level2_child_items, [rowUuid, uuid]);

      console.log(JSON.stringify(test));
      console.log(JSON.stringify(level2_child_items));

      context.commit(SET_LEVEL2_CHILD_ITEM, level2_child_items);
    },
    [GET_CHILD_LIST](context) {
      const line_items = cloneDeep(context.state.line_items);
      if (line_items && line_items.length) {
        const productSet = [...new Set(map(line_items, "product_id"))];
        if (productSet.length) {
          context
            .dispatch(POST, {
              url: "edit-line-item-filters",
              data: { products: productSet },
            })
            .then(({ data }) => {
              const ad_level2_list = cloneDeep(context.state.ad_level2);

              if (data?.level2filter && data?.level2filter?.length) {
                for (let i = 0; i < data.level2filter.length; i++) {
                  if (!ObjectPath.has(ad_level2_list, data.level2filter[i].adw_title)) {
                    ObjectPath.set(ad_level2_list, data.level2filter[i].adw_title, []);
                  }

                  if (!Array.isArray(ad_level2_list[data.level2filter[i].adw_title])) {
                    ad_level2_list[data.level2filter[i].adw_title] = new Array();
                  }

                  if (Array.isArray(ad_level2_list[data.level2filter[i].adw_title])) {
                    ad_level2_list[data.level2filter[i].adw_title].push(data.level2filter[i]);
                  }
                }
              }

              context.commit(SET_ADLEVEL2, ad_level2_list);   

              const ad_select_list = cloneDeep(context.state.ad_select);        

              if (data?.adtitle && data?.adtitle?.length) {
                for (let i = 0; i < data.adtitle.length; i++) {
                  const adw_db_relation = data.adtitle[i]?.adw_db_relation ?? [];
                  if (Array.isArray(adw_db_relation) && adw_db_relation?.length) {                    
                    if (!ObjectPath.has(ad_select_list, data.adtitle[i].id)) {
                      ObjectPath.set(ad_select_list, data.adtitle[i].id, []);
                    }

                    if (!Array.isArray(ad_select_list[data.adtitle[i].id])) {
                      ad_select_list[data.adtitle[i].id] = new Array();
                    }

                    if (Array.isArray(ad_select_list[data.adtitle[i].id])) {
                      const adw_db = adw_db_relation.filter(row => row.activated);

                      adw_db.map((row) => {
                        ad_select_list[data.adtitle[i].id].push({
                          id: row.id,
                          text: row.name,
                          value: row.id,
                          main_category: row.main_category,
                        });
                      });
                    }
                  }
                }
              }

              context.commit(SET_ADSELECT, ad_select_list);

            })
            .catch((error) => {
              console.log({ error });
            });
        }
      }
    },
  },
  mutations: {
    [CLEAR_LINE_ITEM](state) {
      state.is_summary_report = defaultState.is_summary_report;
      state.packages = cloneDeep(defaultState.packages);
      state.line_items = cloneDeep(defaultState.line_items);
      state.uom_list = cloneDeep(defaultState.uom_list);
      state.adtitle_list = cloneDeep(defaultState.adtitle_list);
      state.adwork_list = cloneDeep(defaultState.adwork_list);
      state.ad_select = cloneDeep(defaultState.ad_select);
      state.ad_level2 = cloneDeep(defaultState.ad_level2);
      state.child_items = cloneDeep(defaultState.child_items);
      state.level2_child_items = cloneDeep(defaultState.level2_child_items);
      state.calculation = cloneDeep(defaultState.calculation);
      state.default_item = cloneDeep(defaultState.default_item);
      state.default_child_item = cloneDeep(defaultState.default_child_item);
      state.default_level2_child_item = cloneDeep(defaultState.default_level2_child_item);
    },
    [SET_CALCULATION](state, payload) {
      state.calculation = cloneDeep(payload);
    },
    [SET_SUMMARY_REPORT](state, payload) {
      state.is_summary_report = payload;
    },
    [SET_LEVEL2_CHILD_ITEM](state, payload) {
      state.level2_child_items = cloneDeep(payload);
    },
    [SET_UOM_LIST](state, payload) {
      state.uom_list = cloneDeep(payload);
    },
    [SET_CHILD_ITEM](state, payload) {
      state.child_items = cloneDeep(payload);
    },
    [SET_LINE_ITEM](state, payload) {
      state.line_items = cloneDeep(payload);
    },
    [SET_PACKAGE](state, payload) {
      state.packages = cloneDeep(payload);
    },
    [SET_ADTITLE](state, payload) {
      state.adtitle_list = cloneDeep(payload);
    },
    [SET_ADSELECT](state, payload) {
      state.ad_select = cloneDeep(payload);
    },
    [SET_ADLEVEL2](state, payload) {
      state.ad_level2 = cloneDeep(payload);
    },
    [SET_ADWORK](state, payload) {
      state.adwork_list = cloneDeep(payload);
    },
  },
};
