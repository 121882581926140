import store from "@/core/services/store";
import { SET_GLOBAL_LOADING } from "@/core/services/store/config.module";

class GlobalPlugin {
  constructor() {
    this.activeRequests = 0;
    this.timeout = null;
    this.timeoutLimit = 1000;
  }

  init() {
    //
  }

  trackXHR() {
    let open = window.XMLHttpRequest.prototype.open;
    let send = window.XMLHttpRequest.prototype.send;
    let self = this;

    function openReplacement(/*method, url*/) {
      self.activeRequests++;
      return open.apply(this, arguments);
    }

    function sendReplacement(/*data*/) {
      this.addEventListener('load', function () {
        self.activeRequests--;
        self.checkActiveRequests();
      });

      this.addEventListener('error', function () {
        self.activeRequests--;
        self.checkActiveRequests();
      });

      return send.apply(this, arguments);
    }

    window.XMLHttpRequest.prototype.open = openReplacement;
    window.XMLHttpRequest.prototype.send = sendReplacement;
  }

  checkActiveRequests() {
    store.commit(SET_GLOBAL_LOADING, !!this.activeRequests);
    clearTimeout(this.timeout);
    if (this.activeRequests != 0) {
      this.timeout = setTimeout(() => {
        this.activeRequests = 0;
        store.commit(SET_GLOBAL_LOADING, false);
      }, this.timeoutLimit);
    }
  }
}

export default GlobalPlugin;
